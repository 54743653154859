<template>
  <div class="content">
    <div class="icons">
      <button class="buttonSB" id="openClose-Score" @click="alteraBlocos()">
        <img class="icon-box" :src="iconOpenClose" />
      </button>
      <b-tooltip
        v-if="abreBloco && exibirTootipsConsultas"
        :target="'openClose-Score'"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        Fechar
      </b-tooltip>
      <b-tooltip
        v-if="!abreBloco && exibirTootipsConsultas"
        :target="'openClose-Score'"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        Abrir
      </b-tooltip>
      <button class="buttonSB" id="goTop-Score" @click="goTop">
        <img class="icon-box" :src="iconToTop" />
      </button>
      <b-tooltip
        v-if="exibirTootipsConsultas"
        :target="'goTop-Score'"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        Voltar ao Topo
      </b-tooltip>
    </div>
    <div
      class="dropdown"
      :class="{ 'dropdown--open': abreBloco }"
      v-if="resultados != null"
    >
      <HeaderBlocos :bloco="blocoScore" />
      <div class="body" :class="{ 'body--aberto': abreBloco }">
        <div class="row-info">
          <div class="titles sb-2 sb-right" :id="'BlocoPJ1-cnpj'">CNPJ</div>
          <div class="results sb-4 sb-right text-send">
            {{ formatarCNPJ(resultadoTratado.bk_201_003_cnpj) }}
            <span
              v-if="
                !telaHistorico &&
                !resultadoTratado.bk_201_003_cnpj.includes('*') &&
                !gerandoPdf
              "
              class="material-symbols-outlined icon-send"
              @click="
                selecionaConsultaAdicional(resultadoTratado.bk_201_003_cnpj)
              "
            >
              send
            </span>
          </div>
          <div class="titles sb-1 sb-right" :id="'BlocoPJ1-unidade'">
            Unidade
          </div>
          <div class="results sb-3 sb-right">
            <span
              v-for="(opcao, j) in ['Matriz', 'Filial', 'Não encontrado']"
              :key="j"
              class="tabText"
              :class="{
                selecionado: compararValores(
                  resultadoTratado.bk_201_003_unidade,
                  opcao
                ),
                'nao-selecionado': !compararValores(
                  resultadoTratado.bk_201_003_unidade,
                  opcao
                ),
              }"
            >
              <p class="tabTexts">
                {{ opcao }}
              </p>
              <span v-if="j < 2" style="color: black">|</span>
            </span>
          </div>
          <div
            class="titles sb-1 sb-right"
            :id="'BlocoPJ1-status'"
            style="padding: 0 10px"
          >
            Status
          </div>
          <div class="results sb-1">
            <Check
              :valor="resultadoTratado.bk_201_003_status_cadastral == 'ATIVA'"
            />
          </div>
        </div>
        <div class="row-info">
          <div class="info-col sb-2 sb-right">
            <div class="titles" :id="'BlocoPJ1-cep'">CEP</div>
            <div class="results sb-top">
              {{ resultadoTratado.bk_201_003_num_cep }}
            </div>
          </div>
          <div class="info-col sb-6 sb-right">
            <div class="titles" :id="'BlocoPJ1-rua'">Logradouro</div>
            <div class="results sb-top">
              {{ resultadoTratado.bk_201_003_des_logradouro }}
            </div>
          </div>
          <div class="info-col sb-3 sb-right">
            <div class="titles" :id="'BlocoPJ1-mun'">Município</div>
            <div class="results sb-top">
              {{ resultadoTratado.bk_201_003_des_municipio }}
            </div>
          </div>
          <div class="info-col sb-1">
            <div class="titles" :id="'BlocoPJ1-uf'">UF</div>
            <div class="results sb-top">
              {{ resultadoTratado.bk_201_003_des_uf }}
            </div>
          </div>
        </div>
        <div class="cabecTab">
          <span class="cabecText titulo"> Dados da Matriz </span>
        </div>
        <div class="row-info">
          <div class="titles sb-2 sb-right" :id="'BlocoPJ1-cnpj-m'">CNPJ</div>
          <div class="results sb-4 sb-right text-send">
            {{ formatarCNPJ(resultadoTratado.bk_201_003_cnpj_matriz) }}
            <span
              v-if="
                !telaHistorico &&
                !resultadoTratado.bk_201_003_cnpj_matriz.includes('*') &&
                !gerandoPdf
              "
              class="material-symbols-outlined icon-send"
              @click="
                selecionaConsultaAdicional(
                  resultadoTratado.bk_201_003_cnpj_matriz
                )
              "
            >
              send
            </span>
          </div>
          <div class="titles sb-2 sb-right" :id="'BlocoPJ1-capital-m'">
            Capital Social
          </div>
          <div class="results sb-2 sb-right">
            {{
              formatarNumeroComPonto(
                resultadoTratado.bk_201_003_vlr_capital_social_tratado
              )
            }}
          </div>
          <div
            class="titles sb-1 sb-right"
            :id="'BlocoPJ1-status-m'"
            style="padding: 0 10px"
          >
            Status
          </div>
          <div class="results sb-1">
            <Check
              :valor="
                resultadoTratado.bk_201_003_status_cadastral_matriz == 'ATIVA'
              "
            />
          </div>
        </div>
        <div class="row-info">
          <div class="titles sb-2 sb-right" :id="'BlocoPJ1-razao-m'">
            Razão Social
          </div>
          <div class="results sb-10">
            {{ formatarTexto(resultadoTratado.bk_201_003_des_razao_social) }}
          </div>
        </div>
        <div class="row-info">
          <div class="titles sb-2 sb-right" :id="'BlocoPJ1-nome-m'">
            Nome Fantasia
          </div>
          <div class="results sb-10">
            {{ formatarTexto(resultadoTratado.bk_201_003_des_nome_fantasia) }}
          </div>
        </div>
        <div class="row-info">
          <div class="titles sb-2 sb-right" :id="'BlocoPJ1-ativid-m'">
            Atividade Econômica Principal
          </div>
          <div class="results sb-4 sb-right">
            {{ resultadoTratado.bk_201_003_des_atividade_economica }}
          </div>
          <div class="titles sb-1 sb-right" :id="'BlocoPJ1-cnae-m'">
            CNAE Principal
          </div>
          <div class="results sb-2 sb-right">
            {{ resultadoTratado.bk_201_003_cod_cnae_principal }}
          </div>
          <div class="titles sb-2 sb-right" :id="'BlocoPJ1-sec-m'">
            Qtde CNAE's Secundários
          </div>
          <div class="results sb-1">
            {{ resultadoTratado.bk_201_003_qtd_cnaes_secundarios }}
          </div>
        </div>
      </div>
    </div>
    <div v-for="(tt, i) in tooltipsInfoPj1" :key="i">
      <b-tooltip
        v-if="exibirTootipsConsultas"
        :target="tt.target"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        <div v-html="tt.texto"></div>
      </b-tooltip>
    </div>
  </div>
</template>

<script>
import { userPreferenceStore } from "@/stores/userPreference";
import { blocosSuperBoxStore } from "@/stores/superbox-blocosControle.js";
import { storeAlertasSuperBoxPJ } from "@/stores/superbox-alertas-pj.js";
import { dadosConsultaAddStore } from "@/stores/dadosConsultaAdd";
import { mapWritableState, mapActions } from "pinia";
import HeaderBlocos from "@/components/Modelos/HeaderBlocos.vue";
import Check from "@/components/SuperBox/Check.vue";
import listaTooltipsModelos from "@/helpers/lista_tooltips_modelos.json";

export default {
  name: "BlocoInfoPJ1",

  components: { HeaderBlocos, Check },

  props: {
    listaBlocos: Array,
    resultados: Object,
    modeloSelecionado: Object,
    codigo_agrupador: String,
    exibirTootipsConsultas: Boolean,
    gerandoPdf: Boolean,
  },

  data() {
    return {
      iconToTop: "/img/superbox/icon-totop.png",
      abreBloco: false,
    };
  },

  watch: {
    resultados(newValue, oldValue) {
      if (Object.keys(newValue).length !== 0) {
        this.atualizaAlertas();
        this.abreBloco = true
      }
    },
  },

  methods: {
    selecionaConsultaAdicional(documento) {
      this.abrirSelecaoConsulta(documento, "PJ");
    },

    fechaConsultaAdicional() {
      this.fecharSelecaoConsulta();
    },

    atualizaAlertas() {
      if (Object.keys(this.resultados).length !== 0) {
        for (let key in this.resultados) {
          if (
            key.startsWith("alertas_gerais_") &&
            this.resultados[key] === true
          ) {
            const idAlerta = key.replace("alertas_gerais_", "");
            this.ativaAlerta(idAlerta);
          }
        }
      }
    },

    formatarNumeroComPonto(numero) {
      if (numero != "-") {
        const numeroString = numero.toString().replace(".", ",");
        const partes = numeroString.split(",");
        partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return partes.join(",");
      } else {
        return numero;
      }
    },

    compararValores(str1, str2) {
      const removerAcentos = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      };

      const prepararString = (str) => {
        return str.trim().toLowerCase();
      };

      return (
        prepararString(removerAcentos(str1)) ===
        prepararString(removerAcentos(str2))
      );
    },

    formatarData(dataString) {
      if (dataString != null && dataString != "" && dataString != "-") {
        const data = new Date(dataString);
        const ano = data.getFullYear();
        const mes = (data.getMonth() + 1).toString().padStart(2, "0");
        const dia = data.getDate().toString().padStart(2, "0");

        return `${dia}/${mes}/${ano}`;
      } else {
        return dataString;
      }
    },

    formatarCNPJ(cnpj) {
      if (cnpj != null && cnpj != "-") {
        cnpj = cnpj.replace(/\D/g, "");
        cnpj = cnpj.replace(/^(\d{2})(\d)/, "$1.$2");
        cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
        cnpj = cnpj.replace(/\.(\d{3})(\d)/, ".$1/$2");
        cnpj = cnpj.replace(/(\d{4})(\d)/, "$1-$2");
        if (this.ocultarDados) {
          cnpj = cnpj.replace(
            /(\d{2})\.(\d{3})\.(\d{3})\/(\d{4})\-(\d{2})/,
            (match, p1, p2, p3, p4, p5) => `${p1}.***.***/****-${p5}`
          );
        }
      }

      return cnpj;
    },

    formatarTexto(texto) {
      if (texto != null && texto != "-") {
        if (this.ocultarDados) {
          texto = texto.replace(/[^ ]/g, "*");
        }
      }

      return texto;
    },

    goTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },

    alteraBlocos() {
      this.abreBloco = !this.abreBloco;
      this.alteraBloco(this.abreBloco, 0);
    },

    abreEsteBloco() {
      this.abreBloco = true;
    },

    ...mapActions(blocosSuperBoxStore, ["alteraBloco"]),
    ...mapActions(storeAlertasSuperBoxPJ, ["ativaAlerta"]),

    ...mapActions(dadosConsultaAddStore, [
      "abrirSelecaoConsulta",
      "fecharSelecaoConsulta",
    ]),
  },

  created() {},

  computed: {
    blocoScore() {
      return this.listaBlocos.find(
        (bloco) => bloco.descricao === "Informações Cadastrais"
      );
    },

    iconOpenClose() {
      if (this.abreBloco) {
        return "/img/superbox/icon-dropdown.png";
      } else {
        return "/img/superbox/icon-dropdown-abrir.png";
      }
    },

    telaHistorico() {
      return this.$route.path.includes("/historico");
    },

    tooltipsInfoPj1() {
      return listaTooltipsModelos.bloco_info_pj_1;
    },

    resultadoTratado() {
      const objetoCopiado = { ...this.resultados };

      for (const chave in objetoCopiado) {
        if (
          objetoCopiado[chave] === null &&
          !chave.includes("flag") &&
          !chave.includes("_ind_") &&
          !chave.includes("status_cadastral")
        ) {
          objetoCopiado[chave] = "-";
        }
      }
      return objetoCopiado;
    },

    ...mapWritableState(userPreferenceStore, {
      ocultarDados: "ocultarDados",
      usuarioLogado: "usuarioLogado",
    }),
  },

  mounted() {
    this.atualizaAlertas();
  },
};
</script>
<style lang="scss" scoped>
.body {
  align-items: center;
}

.row-info {
  width: 100%;
  margin-top: 5px;
  margin-left: 0px;
  display: flex;
  max-width: 950px;
  justify-content: center;
  cursor: default;
}

.cabecTab {
  display: flex;
  width: 100%;
  max-width: 950px;

  line-height: 14px;
}

.cabecText {
  display: flex;
  height: 45px;
  width: 100%;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 400;
  background-color: #2f75b5;
  color: white;
  @include flex-center(column);
  margin: 20px 0 5px 0;
}

.info-col {
  padding: 0;
  display: flex;
  flex-direction: column;
  line-height: 16px;
}

.tabText {
  display: flex;
  margin: 0 !important;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  word-break: normal;

  .tabTexts {
    padding: 0 5px;
    margin: 0 !important;
  }
}

.titles {
  padding: 1px 5px;
  background-color: #d9e1f2;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  height: 40px;
  text-align: center;

  @include flex-center(column);
}

.results {
  height: 40px;
  text-align: center;
  border: solid 1px #0070c0;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  padding: 5px;
  word-break: break-word;
  line-height: 12px;
  // flex: 1;
}

.text-send {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 5px;
}

.icon-send {
  color: #0070c0;
  width: 24px;
  font-size: 20px;
  cursor: pointer;
  transition: font-size 0.3s;
  line-height: 18px;

  &:hover {
    font-size: 22px;
  }
}

.selecionado {
  color: #68a490;
  margin-inline: 3px;
  font-weight: 600;
}
.nao-selecionado {
  color: #a6a6a6;
  margin-inline: 3px;
}
</style>
<style src="@/styles/components/superbox_customizado.scss" scoped lang="scss" />
